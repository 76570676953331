import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import MusicPlayer from '../components/player';
import Layout from '../components/layout';
import Hero from '../components/hero';
import { H2, P } from '../components/typography';
import Container from '../components/container';
import ParticipationForm from '../components/participation-form';
import WoodBg from '../components/wood-bg';
// import Card from '../components/card';
import { fluidImageType } from '../helper/prop-types';

const CardWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const CastingPage = ({ data }) => (
    <Layout>
        <Hero height="50vh" image={data.file.childImageSharp.fluid} />
        <Container maxWidth="small">
            <H2>Werde ein Original Lechtaler!</H2>
            <P>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                deserunt mollit anim id est laborum.
            </P>
        </Container>
        <Container maxWidth="small">
            <MusicPlayer />
        </Container>
        <Container maxWidth="medium">
            <ParticipationForm />
        </Container>
        <WoodBg>
            <Container maxWidth="small">
                <H2 whiteColor>Das sind die bisherigen Teilnehmer</H2>
                <P whiteColor>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                    nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                    fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                </P>
            </Container>
            <Container maxWidth="medium">
                <CardWrapper>
                    {/* <Card />
                    <Card />
                    <Card /> */}
                </CardWrapper>
            </Container>
        </WoodBg>
    </Layout>
);

CastingPage.propTypes = {
    data: PropTypes.shape({
        file: fluidImageType,
    }).isRequired,
};

export default CastingPage;

export const query = graphql`
    query {
        file(relativePath: { eq: "hero-bg.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
        }
    }
`;
