import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Sorteo from '@guplabs/sorteo-client';
import map from 'lodash/map';
import { Formik, Form, Field } from 'formik';
import { mixed, date, object, string } from 'yup';
import { colors, fontWeights } from '../helper/variables';
import CheckMark from '../images/check.svg';
import { log } from '../helper/utils';

const FormContainer = styled.div`
    max-width: 80%;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
    z-index: 0;

    input,
    select,
    textarea {
        border: none;
        outline: none;
        background: ${colors.white};
        color: ${colors.black};
        border-radius: 2px;
        border-bottom: 1px solid ${colors.grayLight};

        &::placeholder {
            color: ${colors.black};
        }

        &:required {
            box-shadow: none;
        }

        &:focus {
            outline: none;
        }
    }
`;

const Select = styled.select`
    width: 300px;
    height: 33px;
    background-color: #fff;
    margin-left: 0;
`;

const Label = styled.label`
    display: flex;
    flex-direction: column;
    color: ${colors.black};
    font-size: 0.8em;
    margin: 2em 0;
    position: relative;
    font-weight: ${fontWeights.bold};
`;

const LabelCheckbox = styled(Label)`
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    input {
        position: relative;
        width: 20px;
        margin-right: 10px;
        background: none;
        top: -2px;
        &:before {
            content: '';
            position: absolute;
            width: 20px;
            height: 20px;
            border: none;
            border: 1px solid ${colors.grayLight};
            background: ${colors.white};
            top: -2px;
            left: 0;
        }
        &:checked {
            &:before {
                position: absolute;
                background-image: url(${CheckMark});
                background-repeat: no-repeat;
                background-size: 14px 12px;
                background-position: 50% 50%;
                content: '';
                position: absolute;
                width: 20px;
                height: 20px;
                border: none;
                border: 1px solid ${colors.grayLight};
                top: 3px;
                left: 0;
            }
        }
    }
`;

const LabelVideo = styled(Label)`
    width: 100%;
    input {
        width: 100%;
    }
`;

const Input = styled.input`
    width: 300px;
    height: 30px;
    background-color: #fff;
`;

export const Button = styled.button`
    width: 200px;
    color: ${colors.blueDark};
    font-weight: ${fontWeights.bold};
    background-color: ${colors.white};
    border: 3px solid ${colors.blueDark};
    font-size: 1em;
    display: block;
    margin: 0 auto;
    outline: none;
    cursor: pointer;
    border-radius: 2px;
    text-decoration: none;
    padding: 12px;
    transition: 0.2s;

    &:hover {
        background: ${colors.blueLight};
        color: ${colors.white};
        border: 3px solid ${colors.blueLight};
    }
`;

const FormikInput = ({ field, form: { touched, errors }, ...props }) => (
    <>
        <Input {...field} {...props} />
        {touched[field.name] && errors[field.name] && <div>{errors[field.name]}</div>}
    </>
);

const FormikCheckbox = ({ field, form: { touched, errors, values }, label, ...props }) => (
    <>
        <input {...field} {...props} checked={values[field.name]} />
        {label}
        {touched[field.name] && errors[field.name] && <div>{errors[field.name]}</div>}
    </>
);

const FormikSelect = ({ field, form: { touched, errors }, children }) => (
    <>
        <Select {...field}>{children}</Select>
        {touched[field.name] && errors[field.name] && <div>{errors[field.name]}</div>}
    </>
);

export default class ParticipationForm extends Component {
    static SUBMITSTATES = {
        sending: 'sending',
        success: 'success',
        error: 'error',
    };

    /**
     * Sorteo-Client
     * @type {Sorteo}
     */
    sorteo = null;

    /**
     * State
     * @type {Object}
     */
    state = {
        submitState: '',
        sorteoErrors: null,
    };

    /**
     * Lifecycle Hook bei Mount
     */
    componentDidMount() {
        this.sorteo = new Sorteo(process.env.GATSBY_SORTEO_URL);
        this.sorteo.contestId = process.env.GATSBY_CONTEST;
    }

    /**
     * Handling von Fehlern beim Abschicken des Formulars
     * @param {Object} form FormikBag Objekt
     * @returns {Function}
     */
    handleError = form => error => {
        form.setSubmitting(false);

        let sorteoErrors = error;

        if (error.data && error.data.error && error.data.error.data) {
            sorteoErrors = map(
                error.data.error.data,
                sorteoError => (Array.isArray(sorteoError) ? sorteoError.join(' | ') : sorteoError)
            );
        }

        this.setState({
            submitState: ParticipationForm.SUBMITSTATES.error,
            sorteoErrors,
        });

        log('Error sending data', error);
    };

    /**
     * Sendet die Daten an Sorteo
     * @param {Object} values Daten aus Formik
     * @param {Object} form Formik Formular-Actions
     */
    sendDataToSorteo = (values, form) => {
        this.setState({
            submitState: ParticipationForm.SUBMITSTATES.sending,
        });

        log('Sending data', values);

        this.sorteo.entries
            .store({
                body: {
                    username: values.email,
                    fields: values,
                },
            })
            .then(() => {
                // Alles war erfolgreich!

                form.setSubmitting(false);
                form.resetForm();

                this.setState({
                    submitState: ParticipationForm.SUBMITSTATES.success,
                });

                // track('Online-Bewerbung abgeschickt');
            })
            .catch(this.handleError(form));
    };

    /**
     * Rendert das Formular mit Formik
     */
    render() {
        const { submitState, sorteoErrors } = this.state;

        return (
            <Formik
                initialValues={{
                    salutation: '',
                    firstname: '',
                    lastname: '',
                    email: '',
                    birthday: '',
                    phone: '',
                    video: '',
                    terms: false,
                }}
                onSubmit={this.sendDataToSorteo}
                validationSchema={object().shape({
                    salutation: string().oneOf(
                        ['Herr', 'Frau'],
                        'Bitte geben Sie entweder Frau oder Herr ein.'
                    ),
                    firstname: string()
                        .trim()
                        .max(200, 'Bitte kürzen Sie ihren Vornamen auf 200 Zeichen.')
                        .required('Vorname muss ausgefüllt werden.'),
                    lastname: string()
                        .trim()
                        .max(200, 'Bitte kürzen Sie ihren Nachnamen auf 200 Zeichen.')
                        .required('Nachname muss ausgefüllt werden.'),
                    email: string()
                        .trim()
                        .email('Bitte geben Sie eine gültige E-Mail Adresse ein.')
                        .required('Bitte geben Sie eine E-Mail Adresse ein.'),
                    birthday: date().required('Geburtsdatum muss ausgefüllt werden.'),
                    phone: string()
                        .trim()
                        .max(100, 'Bitte kürzen Sie Ihre Telefonnummer auf 100 Zeichen.'),
                    video: string()
                        .trim()
                        .url('Bitte geben Sie eine gültige URL an.')
                        .required('Video URL muss ausgefüllt werden.'),
                    terms: mixed().oneOf([true], 'Sie müssen den Teilnahmebedingungen zustimmen.'),
                })}
            >
                {({ isSubmitting }) => (
                    <>
                        <Form>
                            <FormContainer>
                                <Label>
                                    Anrede
                                    <Field name="salutation" component={FormikSelect}>
                                        <option>Bitte wählen</option>
                                        <option value="Frau">Frau</option>
                                        <option value="Herr">Herr</option>
                                    </Field>
                                </Label>
                                <Label>
                                    Vorname *
                                    <Field type="text" name="firstname" component={FormikInput} />
                                </Label>
                                <Label>
                                    Nachname *
                                    <Field type="text" name="lastname" component={FormikInput} />
                                </Label>
                                <Label>
                                    Geburtsdatum *
                                    <Field type="date" name="birthday" component={FormikInput} />
                                </Label>
                                <Label>
                                    E-Mail *
                                    <Field type="email" name="email" component={FormikInput} />
                                </Label>
                                <Label>
                                    Telefon
                                    <Field type="tel" name="phone" component={FormikInput} />
                                </Label>
                                <LabelVideo>
                                    Vimeo oder YouTube-URL zu deinem Bewerbungsvideo *
                                    <Field type="url" name="video" component={FormikInput} />
                                </LabelVideo>
                                <LabelCheckbox>
                                    <Field
                                        type="checkbox"
                                        name="terms"
                                        component={FormikCheckbox}
                                        label={
                                            <span>
                                                Ich habe die{' '}
                                                <Link to="/" target="_blank">
                                                    Teilnahmebedingungen
                                                </Link>{' '}
                                                gelesen und erkläre mich damit einverstanden *
                                            </span>
                                        }
                                    />
                                </LabelCheckbox>
                                {/* <LabelCheckbox>
                                        <Input type="checkbox" name="newsletter" required />
                                        Ja, ich möchte über Produktneuheiten, Neuigkeiten rund um Lechtaler per E-Mail
                                        informiert werden
                                    </LabelCheckbox> */}
                                <Button type="submit" disabled={isSubmitting}>
                                    Jetzt teilnehmen!
                                </Button>

                                {submitState === ParticipationForm.SUBMITSTATES.sending && (
                                    <span>SENDE...</span>
                                )}
                                {submitState === ParticipationForm.SUBMITSTATES.success && (
                                    <span>YES! Geile Sache</span>
                                )}
                                {submitState === ParticipationForm.SUBMITSTATES.error && (
                                    <>
                                        Och nö...
                                        <br />
                                        {sorteoErrors}
                                    </>
                                )}
                            </FormContainer>
                        </Form>
                    </>
                )}
            </Formik>
        );
    }
}
