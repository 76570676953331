import React from 'react';
import styled from 'styled-components';
import { size, rem } from 'polished';
import { Play } from 'styled-icons/fa-solid/Play';
import { VolumeUp } from 'styled-icons/fa-solid/VolumeUp';
import { fontSizes, colors } from '../helper/variables';
import PlayerBaseImage from '../images/playerbg.png';
import PlayerFrameBg from '../images/playerframebg.png';
import PlayerDurationBg from '../images/playerdurationbg.png';

const PlayerWrapper = styled.div`
    position: relative;
    ${size(rem(326), rem(326))};
    overflow: hidden;
    border-radius: 50%;
    box-shadow: 0 1px 13px rgba(0, 0, 0, 0.55);
    margin: 0 auto;
    user-select: none;
`;

const PlayerImage = styled.img`
    position: absolute;
    top: 0;
    width: 100%;
    height: auto;
`;
const PlayerFrame = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 12.9375rem;
    background: url(${PlayerFrameBg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
`;
const PlayerProgressWrapper = styled.div`
    position: absolute;
    bottom: 12px;
    left: 50%;
    width: 22.375rem;
    height: 140px;
    overflow: hidden;
    margin: 0 0 0 -9.275rem;
`;
const PlayerProgressWheel = styled.div`
    position: absolute;
    bottom: 0;
    width: 270px;
    height: 279px;
    border: 14px solid #201b2b;
    border-radius: 100%;
    opacity: 0.5;
`;

const PlayerProgressWheelProgress = styled.div`
    position: absolute;
    bottom: -14px;
    left: 50%;
    width: 392px;
    height: 392px;
    overflow: hidden;
    margin: 0 0 0 -196px;
    border-radius: 100%;
    transform-origin: 50% 50%;
    background-image: ulr(${PlayerDurationBg});
    transform: rotate(145deg);
`;

const PlayerProgressPointer = styled.div`
    position: absolute;
    bottom: -14px;
    left: 50%;
    width: 392px;
    height: 392px;
    overflow: hidden;
    margin: 0 0 0 -196px;
    border-radius: 100%;
    transform-origin: 50% 50%;
`;
const PlayerControls = styled.div`
    position: absolute;
    top: 45px;
    left: 50%;
    width: 52px;
    height: 52px;
    margin-left: -26px;
    background: #ffffff;
    border-radius: 50%;
}
`;
const PlayButton = styled(Play)`
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    font-size: 32px;
    color: ${colors.blueDark};
    margin: -9px 0 0 -8px;
    transition: all 0.3s;
    &:hover {
        box-shadow: 0 0 5px #f5696c;
        text-shadow: 0 0 5px #fff;
    }
`;
const VolumeUpDownWrapper = styled.div`
    position: absolute;
    top: -32px;
    left: 50%;
    width: 20px;
    margin-left: -10px;
    color: ${colors.white};
`;
const VolumeUpButton = styled(VolumeUp)``;

const PlayerMusicInfo = styled.div`
    position: absolute;
    bottom: 35px;
    left: 50%;
    width: 200px;
    margin: 0 0 0 -100px;
    text-align: center;
    font-family: Segoe UI, sans-serif;
    font-size: 12px;
    color: #fff;
    cursor: default;
`;
const PlayerMusicInfoName = styled.h4`
    display: block;
    margin: 18px 0 5px;
    font-size: ${fontSizes.m};
`;
const PlayerMusicInfoDuration = styled.p`
    display: inline-block;
    padding: 0 10px;
    vertical-align: middle;
    font-size: 13px;
    font-style: normal;
    border-radius: 10px;
`;

const Player = () => (
    <PlayerWrapper>
        <PlayerImage src={PlayerBaseImage} />
        <PlayerFrame>
            <PlayerControls>
                <VolumeUpDownWrapper>
                    <VolumeUpButton />
                </VolumeUpDownWrapper>
                <PlayButton />
            </PlayerControls>
            <PlayerMusicInfo>
                <PlayerMusicInfoName>Der Lechtaler Song</PlayerMusicInfoName>
                <PlayerMusicInfoDuration> D. comes</PlayerMusicInfoDuration>
            </PlayerMusicInfo>
            <PlayerProgressWrapper>
                <PlayerProgressWheel>
                    <PlayerProgressWheelProgress />
                </PlayerProgressWheel>
            </PlayerProgressWrapper>
            <PlayerProgressPointer />
        </PlayerFrame>
    </PlayerWrapper>
);

export default Player;
